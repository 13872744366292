import { Message } from "element-ui";

var zero = function(value) {
    if (value < 10) {
        return '0' + value;
    }
    return value;
};

var getDateTimeString = function(objDate) {
    var year = objDate.getFullYear();
    var month = zero(objDate.getMonth() + 1);
    var day = zero(objDate.getDate());
    return year + "-" + month + "-" + day + " 00:00:00";
};

export function isValidResponse(response) {
    if (response.code === "000") {
        if (response.data) {
            return true;
        } else {
            return false;
        }
    } else {
        // Message.error(response.message);
        return false;
    }
};

export function getAddressList(str) {
    var result = ["","",""];
    if (str && str != "") {
        var preft1 = str.substr(2, 1);
        if (preft1 === "都" || preft1 === "道" || preft1 === "府" || preft1 === "県") {
            result[0] = str.substr(0, 3);
            var temp = str.substr(3);
            var tempList = temp.split(" ");
            if (tempList.length > 1) {
                result[1] = tempList[0];
                for (var i = 1; i < tempList.length; i++) {
                    result[2] += tempList[i];
                }
            } else {
                tempList = temp.split("　");
                if (tempList.length > 1) {
                    result[1] = tempList[0];
                    for (var i = 1; i < tempList.length; i++) {
                        result[2] += tempList[i];
                    }
                } else {
                    result[1] = tempList[0];
                }
            }
            return result;
        }

        var preft2 = str.substr(3, 1);
        if (preft2 === "都" || preft2 === "道" || preft2 === "府" || preft2 === "県") {
            result[0] = str.substr(0, 4);
            var temp = str.substr(4);
            var tempList = temp.split(" ");
            if (tempList.length > 1) {
                result[1] = tempList[0];
                for (var i = 1; i < tempList.length; i++) {
                    result[2] += tempList[i];
                }
            } else {
                tempList = temp.split("　");
                if (tempList.length > 1) {
                    result[1] = tempList[0];
                    for (var i = 1; i < tempList.length; i++) {
                        result[2] += tempList[i];
                    }
                } else {
                    result[1] = tempList[0];
                }
            }
            return result;
        }
    }
    return result;
};

export function zeroPad(value) {
    return zero(value);
};

export function getDateTimeStr1(objDate) {
    return getDateTimeString(objDate);
};

export function getFormatYm(objDate) {
    var dateTime = new Date(objDate);
    var year = dateTime.getFullYear();
    var month = zero(dateTime.getMonth() + 1);
    return year + "/" + month ;
};

export function getLastDate(objDate, addMonth) {
    var endDay = new Date(objDate);
    endDay.setDate(1);
    endDay.setMonth(endDay.getMonth() + 1 + addMonth);
    endDay.setDate(0);
    return getDateTimeString(endDay);
};

export function getNextMonthDay(objDate, addMonth, idxDay) {
    var endDay = new Date(objDate);
    endDay.setDate(1);
    endDay.setMonth(endDay.getMonth() + addMonth);
    endDay.setDate(idxDay);
    return getDateTimeString(endDay);
};

const digitsRE = /(\d{3})(?=\d)/g
export function currency(value, currency, decimals) {
    value = parseFloat(value);
    if (!isFinite(value) || (!value && value !== 0)) return '';
    currency = currency != null ? currency : '$';
    decimals = decimals != null ? decimals : 2;
    var stringified = Math.abs(value).toFixed(decimals);
    var _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
    var i = _int.length % 3;
    var head = i > 0 ? (_int.slice(0, i) + (_int.length > 3 ? ',' : '')) : '';
    var _float = decimals ? stringified.slice(-1 - decimals) : '';
    var sign  = value < 0 ? '-' : '';
    return sign + currency + head + _int.slice(i).replace(digitsRE, '$1,') + _float;
};
